<template>
  <div>
    <content-template
      :menuName="$t('sidebar.experts')"
      :newButton="role_type == 'superadmin' || role_type == 'admin'"
      @moveToForm="moveToUserForm()"
    >
      <template #table-header>
        <span
          :class="`s-mb-30 ${isMobile ? 's-fullWidth' : 's-flex s-mt--65'}`"
        >
          <Input
            class="s-ml-auto"
            label=""
            :placeholder="$t('general.search')"
            v-model="request.search"
            @keyup="setTime()"
            @keydown="clearTime()"
            @keydown.tab="setTime()"
            @keyup.tab="clearTime()"
            @keypress.enter="getData()"
          />
        </span>
      </template>
      <template #table>
        <TableCustom
          :columns="
            role_type == 'stakeholder' || role_type == 'public'
              ? columns
              : columnsFull
          "
          :request="request"
          :fetchData="fetchData"
          :data="rows"
          @setSortBy="setSortBy"
          @setShow="setShow"
          @getData="getData"
          @mouseleave="popup = null"
          @togglePopup="togglePopup"
        >
          <template #index="props">
            {{ curNumber(props.index) }}
          </template>
          <template #expertise="props">
            <span
              class="s-text-black"
              v-for="(e, i) in props.row.expertises"
              :key="`expert-${i}`"
            >
              {{ e.name }}
              <span
                class="s-text-black"
                v-if="i < props.row.expertises.length - 1"
                >,
              </span>
            </span>
          </template>

          <template #action="props">
            <div class="r-menu">
              <img
                class="r-isClickAble"
                :src="require('@/assets/icons/others/more.svg')"
              />
              <div
                class="r-menu-items"
                v-if="popup == curNumber(props.index)"
                @mouseleave="popup = -1"
              >
                <div
                  class="r-menu-item s-center-flex"
                  @click.prevent="setUpdate(props.row)"
                >
                  {{ $t("general.update") }}
                </div>
                <div
                  class="r-menu-item s-text-red s-center-flex"
                  @click.prevent="openDeleteDialog(true, props.row)"
                >
                  {{ $t("general.changeStatus") }}
                </div>
              </div>
            </div>
          </template>
        </TableCustom>
      </template>
    </content-template>

    <Modal v-if="dialogDelete" :title="$t('general.warning')">
      <template #modalContent>
        <p>
          {{ $t("users.modalStatus") }}
          {{
            lang == "id"
              ? `status ${$t("sidebar.experts").toLowerCase()}`
              : `expert's status`
          }}?
        </p>

        <div class="s-mtb-20 s-flex">
          <div class="s-ml-auto">
            <Button @click.prevent="onChangeStatus">
              {{ $t("general.yes") }}
            </Button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import tableMixins from "@/mixins/tableMixins";
import form from "@/mixins/form";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ContentTemplate },
  mixins: [tableMixins, form],
  data() {
    return {
      columns: [
        {
          label: "No",
          fieldName: "index",
          isSortable: false,
          class: "r-table-firstColumn",
        },
        {
          label: "general.name",
          fieldName: "name",
          isSortable: true,
          class: "",
        },
        {
          label: "Email",
          fieldName: "email",
          isSortable: true,
          class: "",
        },
        {
          label: "general.institution",
          fieldName: "institution",
          isSortable: true,
          class: "",
        },
        {
          label: "general.position",
          fieldName: "position",
          isSortable: true,
          class: "",
        },
        {
          label: "sidebar.expertise",
          fieldName: "expertise",
          isSortable: true,
          class: "",
        },
        {
          label: "general.phoneNumber",
          fieldName: "phone_number",
          isSortable: true,
          class: "",
        },
        {
          label: "expert.rating",
          fieldName: "rating",
          isSortable: true,
          class: "",
        },
      ],
      columnsFull: [],

      current_id: "",
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    ...mapGetters({
      rows: "expert/getExpert",
      role_type: "auth/getRole",
      request: "expert/getRequest",
    }),
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    dialogDelete() {
      return this.$store.getters["getModal"];
    },
  },

  methods: {
    ...mapActions({
      setExpert: "expert/setExpertData",
      setModal: "setModal",
      delete_expert: "user/changeStatus",
    }),
    async onChangeStatus() {
      await this.delete_expert(this.current_id);
      this.setModal(false);
      this.setExpert();
    },
    async initData() {
      await this.getData();
      this.columnsFull = this.columns.concat(
        {
          label: "Status",
          fieldName: "status",
          isSortable: true,
          class: "",
        },
        {
          label: "general.action",
          isSortable: false,
          fieldName: "action",
          class: "r-table-actionColumn",
        }
      );
    },
    moveToUserForm() {
      this.$router.push(
        this.$translate({
          name: "New Users",
          params: { inputType: "New", userType: "Expert" },
        })
      );
    },
    async getData(type = 0) {
      this.fetchData = true;
      if (type === 0) this.request.page = 1;
      else this.request.page += type;
      await this.setExpert();
      this.fetchData = false;
      // const response = this.getResponse("BRRD");
      // if (response.status !== 1) {
      //   this.showSnackbar({
      //     type: "error",
      //     text: response.msg,
      //   });
      // }
    },

    setUpdate(data) {
      this.$router.push(
        this.$translate({
          name: "Edit Users",
          params: {
            inputType: "Edit",
            userType: "Expert",
            id: data.id,
          },
        })
      );
    },

    openDeleteDialog(state, data) {
      this.setModal(state);
      this.current_id = data.id;
    },
  },
};
</script>
